* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;   
}
.nav-links {
    background-color: white;
    width: fit-content;
    height: 100%;
}
.nav-link {
    display: block;
    padding: 20px;
    text-decoration: none;
    min-width: max-content;
    color: rgb(42, 54, 88);
    font-weight: bolder;
}
.nav-link:hover {
    background-color: rgb(42, 54, 88);
    color: white;
}
.container {
    display: flex;
    flex-direction: row;
    position: fixed;
    height: 100%;
    width: 100%;
}
main {
    width: 100%;
    height: 100%;
    overflow: scroll !important;
}
.section {
    padding: 20px;
    background-color: whitesmoke;
    min-height: 97vh;
}
.heading {
    display: flex;
    flex-direction: row;
    margin: -20px;
    padding: 20px;
    background-color: rgb(42, 54, 88);
    color: white;
}
.heading h1 {
    margin-right: auto;
}
button {
    background-color: white;
    color: rgb(42, 54, 88);
    padding: 10px;
    border: none;
    font-weight: bolder;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}
.update-button:hover {
    background-color: whitesmoke;
}
.user-profile {
    margin: 20px 0;
}
input, textarea {
    width: 100%;
    border: none;
    border-left: solid 5px rgb(42, 54, 88);
    padding: 10px;
}
.img-name-title {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 20px 0;
}
.name-title {
    padding: 20px;
    width: 75%;
}
.name-title input {
    padding: 10px;
    margin: 10px;
    display: block;
    width: 100%;
}
.img-div {
    width: 50%;
    height: fit-content;
    padding: 20px;
    cursor: pointer;
}
.img-div img {
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
}
.img-input {
    display: none;
}
.bio-input {
    width: 100%;
    min-height: 300px;
}
.contact {
    width: 50%;
}
.contact-info {
    width: 100%;
    padding: 20px;
}
.contact-info-input-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
}
.contact-info-input-div input {
    width: 90%;
    margin-right: auto;
}
.delete-button {
    padding: 5px 10px;
    border: solid 2px rgb(255, 79, 79);
    color: rgb(255, 79, 79);
}
.delete-button:hover {
    background-color: rgb(255, 79, 79);
    color: white;
}
.add-button {
    padding: 5px 10px;
    border: solid 2px rgb(44, 160, 44);
    color: rgb(44, 160, 44);
}
.add-button:hover {
    background-color: rgb(44, 160, 44);
    color: white;
}
.ed-exp {
    padding: 20px;
    margin: 50px 0;
}
.ed-exp-edit {
    border: solid 2px rgb(42, 54, 88);
    border-radius: 5px;
    padding: 20px;
    margin: 10px 0;
}
.ed-exp-edit input, .ed-exp-edit textarea {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
}
.ed-exp-edit textarea {
    min-height: 200px;
}
.date-input {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
}
.date-input input {
    width: 85%;
    margin-left: auto;
}
.splash-screen {
    position: absolute;
    width: 85%;
    height: 100%;
    z-index: 999;
    background-color: rgba(42, 54, 88, 0.863);
    color: white;
    font-weight: bold;
    margin: -20px;
}
.splash-screen .updating-container {
    position: absolute;
    width: fit-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
}
.updating {
    border: solid 4px rgb(42, 54, 88);
    border-radius: 50%;
    border-top: solid 4px white;
    display: inline-block;
    width: 14px;
    height: 14px;
    animation: spin 1s infinite linear;
    margin: 0 5px;
}
.splash-screen .updating {
    border: solid 5px white;
    border-top: solid 5px rgb(42, 54, 88);
    width: 50px;
    height: 50px;
}
@keyframes spin {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 360deg;
    }
}